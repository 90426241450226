import React from 'react'
import Footer from './Footer'

const Service = () => {
    return (
        <>

            {/* Service-penel1 */}

            <div className='Service-penel1'>
                <h1>We offer our Services at wide range of location</h1>
                <p>
                    We provide quickest service to our customers; our quick service has won us many of satisfied customers across the country. We have dedicated team of highly experienced technicians to provide on-call support, In case customer need quick help over phone. Our all the Authorized Distributors are committed to provide complete service for Soda Fountain Machines distributed by them, so the customer can get quick support from nearest Authorized Distributor.
                </p>

                <table>
                    <tbody>
                        <tr>
                            <td>Chhattisgarh</td>
                            <td>Maharastra</td>
                            <td>Orrisa</td>
                        </tr>
                        <tr>
                            <td>Assam</td>
                            <td>Punjab</td>
                            <td>Madhya Pradesh</td>
                        </tr>
                        <tr>
                            <td>Delhi</td>
                            <td>Uttar Pradesh</td>
                            <td>Jharkhand</td>
                        </tr>
                        <tr>
                            <td>Goa</td>
                            <td>Andhra Pradesh</td>
                            <td>West Bengal</td>
                        </tr>
                    </tbody>
                </table>

                <p>NOTE:- OUR CALL CENTER SERVICE WILL AVAILABLE 24*7 :</p>

                <div className={`service-btn`} style={{ textAlign: 'center' }}>
                    <button>Testttt
                        <span class="arrow right">&#8594;</span>
                    </button>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Service
