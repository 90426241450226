import './App.css';
import Navbar from './component/Navbar';
import Home from './component/Homepage'
import { Route, Routes, useNavigate } from 'react-router-dom';
import About from './component/About';
import Slider from './component/Slider';
import Footer from './component/Footer';
import Products from './component/Products';
import Service from './component/Service';
import Portfolio from './component/Portfolio';
import DynamicPage from './component/DynamicPage';
import Contact from './Contact';
import { useState } from 'react';


function App() {
  const navigate = useNavigate()
  const [content, setContent] = useState('<h1>This is a Dynamic Page</h1>')

  function handleContent(c) {
    setContent(c);
  }


  function handleNavigate(content) {
    handleContent(content);
    navigate('/Dynamic');
  }


  return (
    <>

      {/* navbar */}
      <Navbar  handleContent={handleContent} />
      {/* navbar */}

      {/* slider */}
      <Slider />
      {/* slider */}


      <Routes>
        <Route path='/' Component={Home} />
        <Route path='/Dynamic' element={<DynamicPage content={content} />} />
        <Route path='/About' Component={About} />
        <Route path='/Products' Component={Products} />
        <Route path='/Service' Component={Service} />
        <Route path='/Portfolio' Component={Portfolio} />
        <Route path='/Contact' Component={Contact} />
      </Routes>

      {/* Footer */}
      {/* <Footer /> */}
      {/* Footer */}


    </>
  );
}

export default App;
