import React from 'react'
import Footer from './Footer'

const Product = () => {
    return (

        <>
            <div class="Products-penel1">
                <button class="Products-penel1-button">
                    Soda Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Icecream Softy
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Water ATM
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Coffee Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Sugar Cane Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Popcorn Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    RO Plant
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Namkeen
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Panipuri Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Coconut Cart
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Pani Pouch Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Pepsi Pouch Filling Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Pet Bottle Cold Drink Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Sweet Corn Cart
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Water Cooler Machine
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Sweet Display Counter
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Food Cart
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Faluda Gadhi
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Cotton Candy
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Adbule Plant Urea Plant
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    DM Plant Description
                    <span class="arrow right">&#8594;</span>
                </button>
                <button class="Products-penel1-button">
                    Coolant Mixing Machine ½ Hp
                    <span class="arrow right">&#8594;</span>
                </button>
            </div>

            <Footer />

        </>



    )
}

export default Product
