import { Icon } from '@iconify/react/dist/iconify.js';
import React, { useState, useEffect } from 'react';
import cococart from "../assets/NewSlider/cococart.webp"
import coolant from "../assets/NewSlider/coolant.webp"
import slide3 from "../assets/NewSlider/cotton candy machine.webp"
import slide4 from "../assets/NewSlider/def urea plant.webp"
import slide5 from "../assets/NewSlider/juice filling machine.webp"
import slide6 from "../assets/NewSlider/lichi juice filling machine.webp"
import slide7 from "../assets/NewSlider/pani pouch machine.webp"
import slide8 from "../assets/NewSlider/pet bottle cold drink machine.webp"
import slide9 from "../assets/NewSlider/popcorn machine.webp"
import slide10 from "../assets/NewSlider/sugar cane juice machine.webp"
import slide11 from "../assets/NewSlider/sweet corn cart.webp"


const slides = [
  {
    image: 'https://sodaclubindia.in/admin/frontphotos/1569298595_SliderImage.png',
    heading: 'Welcome to Soda Club India',
    paragraph: 'Get the best flavours of your choice in soda with highly hygienic treatment',
  },
  {
    image: 'https://sodaclubindia.in/admin/frontphotos/1569298497_SliderImage.png',
    heading: 'Water ATM',
    paragraph: 'Installing water ATM in your home or office and get clean and safe drinking water.',
  },
  {
    image: 'https://sodaclubindia.in/admin/frontphotos/1569298236_SliderImage.png',
    heading: 'RO Plant',
    paragraph: 'RO Water Plant that are available as RO and UV treated drinking water',
  },
  {
    image: 'https://sodaclubindia.in/admin/frontphotos/1569232535_SliderImage.png',
    heading: 'Ice Cream Softy',
    paragraph: 'Delicious and varieties of cream for you to enjoy in every season',
  },
  {
    image: 'https://sodaclubindia.in/admin/frontphotos/1569067697_SliderImage.png',
    heading: 'Sweet Counter',
    paragraph: 'Beautiful counter and showcase for sweets',
  },
  {
    image: cococart,
    heading: 'CocoCart',
    paragraph: 'CocoCart is typically associated with a platform or service that sells products related to coconuts',
  },
  {
    image: coolant,
    heading: 'Coolant',
    paragraph: 'Coolants are fluids used to remove heat from engines or machinery, preventing overheating.',
  },
  {
    image: slide3,
    heading: 'Cotton Candy Machine',
    paragraph: 'Cotton candy machines are fun devices used to create the fluffy, sugary treat we all love!',
  },
  {
    image: slide4,
    heading: 'DEF Urea Plant',
    paragraph: 'A DEF (Diesel Exhaust Fluid) urea plant produces urea.',
  },
  {
    image: slide5,
    heading: 'Juice Filling Machine',
    paragraph: 'A juice filling machine is designed to automate the process of filling bottles or containers with juice.'
  },
  {
    image: slide6,
    heading: 'Lichi Juice Filling Machine',
    paragraph: 'A litchi juice filling machine is specifically designed to fill bottles or containers with litchi juice',
  },
  {
    image: slide7,
    heading: 'Pani Pouch Machine',
    paragraph: 'A pani pouch machine is designed to fill and seal pouches with flavored water (pani)',
  },
  {
    image: slide8,
    heading: 'Pet Bottle Cold Drink Machine',
    paragraph: "A PET bottle cold drink machine is designed to fill and package carbonated or non-carbonated beverages in PET"
  },
  {
    image: slide9,
    heading: 'Popcorn Machine',
    paragraph: 'A popcorn machine is a device used to pop corn kernels'
  },
  {
    image: slide10,
    heading: 'Sugar Cane Juice Machine',
    paragraph: 'A sugar cane juice machine extracts juice from fresh sugar cane stalks'
  },
  {
    image: slide11,
    heading: 'Sweet Corn Cart',
    paragraph: 'A sweet corn cart is a mobile vendor setup designed for selling boiled or grilled sweet corn on the cob'
  }
];

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);


  return (
    <>

      {/* Home-slider */}

      <div className='slider' style={{ position: 'relative' }}>
        <div className='slide'>
          <button className='btn-for-slide' onClick={prevSlide} style={{ width: 'auto', height: 'auto', margin: 'auto', position: 'absolute', top: '40%', borderRadius: '10%', left: '10px' }}>
            {/* <Icon icon="maki:arrow" style={{ color: "#0e58a1" }} /> */}
            <Icon icon="tabler:arrow-left" style={{ color: "#0e58a1" }} />
          </button>

          <div className='slide-image'>
            <img src={slides[currentSlide].image} alt={slides[currentSlide].heading} />
          </div>
          <div className='slide-content'>
            <h2>{slides[currentSlide].heading}</h2>
            <p>{slides[currentSlide].paragraph}</p>
            <button onClick={() => alert('Learn More clicked!')}>Learn More</button>
          </div>

          <button className='btn-for-slide' onClick={nextSlide} style={{ width: 'auto', height: 'auto', margin: 'auto', position: 'absolute', top: '40%', borderRadius: '10%', right: '10px' }}>
            <Icon icon="maki:arrow" style={{ color: "#0e58a1" }} />
          </button>

        </div>

        {/* next slide */}

      </div>

    </>
  )
}

export default Slider
