import React from 'react'
import Footer from './component/Footer'

const Contact = () => {
    return (
        <>

            {/* Contact-penel1 */}

            <div className='Contact-heading'>
                <h1>Contact Info</h1>
            </div>

            <div className='Contact-penel1'>
                <div className='Contact-penel1-section'>
                    <div className='Contact-penel1-headingbox'>
                        <h1>Mail Us</h1>
                    </div>
                    <form>
                        <input type='text' placeholder='Name' required />
                        <input type='tel' placeholder='Contact No.' required />
                        <input type='email' placeholder='Email Address' required />
                        <textarea placeholder='Message' required></textarea>
                        <button type='submit'>Send Now</button>
                    </form>
                </div>

                <div className='Contact-penel1-section'>
                    <div className='Contact-penel1-headingbox'>
                        <h1>Contact Info</h1>
                    </div>
                    <h2>Address:</h2>
                    <p><span className='icon'>📍</span> Shree Enterprises Shop no- 145, Dhillon compex, Akash Ganga, Supela Bhilai(c.g.)</p>
                    <h2>Phone Number:</h2>
                    <p><span className='icon'>📞</span> +91 7879799992, +91 7999239898</p>
                    <h2>Email Address:</h2>
                    <p><span className='icon'>✉️</span> info@sodaclubindia.com
                        shree.enterprises.bhilai@gmail.com</p>
                </div>


                <div className='Contact-penel1-section'>
                    <div className='Contact-penel1-headingbox'>
                        <h1>Branch Office</h1>
                    </div>
                    <h2>Address:</h2>
                    <p><span className='icon'>📍</span> House no 7, Amarawati Path, Christian Basti, Guwahati, Assam 781005</p>
                    <h2>Phone Number:</h2>
                    <p><span className='icon'>📞</span>  7896852224</p>
                    <h2>Email Address:</h2>
                    <p><span className='icon'>✉️</span> info@sodaclubindia.com</p>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Contact
