import React from 'react'
import Footer from './Footer'

const About = () => {
  return (
    <>

      {/* About-penel1 */}

      <div className='About-panel1'>
        <div className='left-section'>
          <h2>Our Founders Story</h2>
          <p>Soda club is a registered trade mark of Shree Enterprises, a unique concept which is successfully running. We are providing Soda fountain machine & spare parts of soda fountain along with all guidance to set up a Soda club outlet. Since our establishment, we are engaged in manufacturing and trading of comprehensive range of machinery that is widely used for small scale industry. Our products are known for their high quality performance, efficiency and reliability.</p>

          <h2>Customer satisfaction is the absolute key to success</h2>
          <p>At, shree enterprises, we maintain a team of highly experienced technicians and quality checkers who are very innovative, technically competent and productivity oriented. We emerged as the leader for manufacturing and supplying best quality soda fountain machines in the industry.</p>
        </div>
        <div className='right-section'>
          <img src='https://sodaclubindia.in/images/mission_5.jpg' alt='Description of the image' />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default About
