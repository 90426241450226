import React from 'react'
import Footer from './Footer'
import Basana_Chhattishgarh from '../assets/Images/Basana_Chhattishgarh.png'
import BhavaniPatanam from '../assets/Images/BhavaniPatanam.png'
import Bhilai_Chhattishgarh from '../assets/Images/Bhilai_Chhattishgarh.png'
import NIT_Raipur from '../assets/Images/NIT_Raipur.png'
import Phool_Chowk_Raipur from '../assets/Images/Phool_Chowk_Raipur.png'
import Photot_2018 from '../assets/Images/Photot_2018.png'
import RajnandGanv_Chhatisgarh from '../assets/Images/RajnandGanv_Chhatisgarh.png'
import Sambalpur_odisa from '../assets/Images/Sambalpur_odisa.png'
import Saraipali_CG from '../assets/Images/Saraipali_CG.png'
import Sundergarh_orisa from '../assets/Images/Sundergarh_orisa.png'

const Portfolio = () => {
    return (
        <>

            {/* Portfolio-Panel1 */}

            <div className="Portfolio-penel1">
                <div className="image-box">
                    <img src={Basana_Chhattishgarh} alt="Image 1" />
                    <a>Basna-chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={BhavaniPatanam} alt="Image 2" />
                    <a>Bhavanipatnam</a>
                </div>
                <div className="image-box">
                    <img src={Bhilai_Chhattishgarh} alt="Image 3" />
                    <a>Bhilai_Chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={Bhilai_Chhattishgarh} alt="Image 4" />
                    <a>Bhilai_Chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={NIT_Raipur} alt="Image 5" />
                    <a>NIT_Raipur_Chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={Phool_Chowk_Raipur} alt="Image 6" />
                    <a>Phool_Chowk_Raipur_CG</a>
                </div>
                <div className="image-box">
                    <img src={Photot_2018} alt="Image 7" />
                    <a>photo_2018-11-12</a>
                </div>
                <div className="image-box">
                    <img src={RajnandGanv_Chhatisgarh} alt="Image 8" />
                    <a>Rajnandgaon_Chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={Sambalpur_odisa} alt="Image 9" />
                    <a>Sambalpur_Orissa</a>
                </div>
                <div className="image-box">
                    <img src={Saraipali_CG} alt="Image 10" />
                    <a>Saraipali_Chhattisgarh</a>
                </div>
                <div className="image-box">
                    <img src={Sundergarh_orisa} alt="Image 11" />
                    <a>Sundergarh_Orissa</a>
                </div>
                <div className="image-box">
                    <img src={Sundergarh_orisa} alt="Image 11" />
                    <a>Sundergarh_Orissa</a>
                </div>
            </div>

            <Footer />
            {/* <Footer></Footer> */}

        </>
    )
}

export default Portfolio
