import React from 'react'

const Footer = () => {
  return (
    <>

      {/* Footer1*/}

      <footer>
        <div class="footer-section">
          <div class="footer-col">
            <h3>Soda Machine Manufacturer</h3>
            <a href="#">Andhra Pradesh</a>
            <a href="#">Arunachal Pradesh</a>
            <a href="#">Assam</a>
            <a href="#">Bihar</a>
            <a href="#">Chhattisgarh</a>
            <a href="#">Delhi</a>
            <a href="#">Goa</a>
            <a href="#">Gujarat</a>
          </div>
          <div class="footer-col">
            <h3>Soda Machine Distributor</h3>
            <a href="#">Haryana</a>
            <a href="#">Himanchal Pradesh</a>
            <a href="#">Jammu and Kashmir</a>
            <a href="#">Jharkhand</a>
            <a href="#">Karnatka</a>
            <a href="#">Kerala</a>
            <a href="#">Madhya Pradesh</a>
            <a href="#">Maharashtra</a>
          </div>
          <div class="footer-col">
            <h3>Soda Machine Dealer</h3>
            <a href="#">Manipur</a>
            <a href="#">Meghalaya</a>
            <a href="#">Mizoram</a>
            <a href="#">Nagaland</a>
            <a href="#">Odhisha</a>
            <a href="#">Punjab</a>
            <a href="#">Rajasthan</a>
            <a href="#">Sikkim</a>
          </div>
          <div class="footer-col">
            <h3>Soda Vending Machine in India</h3>
            <a href="#">Tamil Nadu</a>
            <a href="#">Telangana</a>
            <a href="#">Tripura</a>
            <a href="#">Uttarakhand</a>
            <a href="#">Uttar Pradesh</a>
            <a href="#">West Bengal</a>
          </div>
          <div class="footer-col">
            <h3>Follow Us</h3>
            <div class="social-icons">
              <a href="#" aria-label="Facebook"><i class="fa-brands fa-facebook-f"></i></a>
              <a href="#" aria-label="Twitter"><i class="fa-brands fa-twitter"></i></a>
              <a href="#" aria-label="Instagram"><i class="fa-brands fa-square-instagram"></i></a>
              <a href="#" aria-label="YouTube"><i class="fa-brands fa-youtube"></i></a>
            </div>
          </div>
        </div>

        <div className='footer2'>
          <p>Copyright ©All rights reserved | Developed By TechCherry</p>
        </div>
      </footer>

    </>
  )
}

export default Footer
