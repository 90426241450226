import React from 'react'

function DynamicPage({ content }) {
  console.log("content");
  console.log(content)
  // const {content,handleContent} = useContext(DynamicContext);

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default DynamicPage